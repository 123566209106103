:root {
  --plyr-color-main: theme('colors.cherry');
}

html {
  @apply h-full;
}

.default-wrapper {
  @apply max-w-8xl px-5 lg:px-16 2xl:px-[6.875rem] w-full mx-auto;
}

.simple-wrapper {
  @apply max-w-8xl px-5 lg:px-16 2xl:px-[14rem] w-full mx-auto;
}

.swiper-pagination-progressbar {
  @apply relative; /* lg:mr-16 2xl:mr-[6.875rem]; */
}

.swiper-container-horizontal > .swiper-pagination-progressbar {
  @apply relative h-[1px] w-auto bg-beige bg-opacity-20;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  @apply bg-cherry;
}

.swiper-pagination {
  @apply w-auto relative bottom-auto left-auto body-text text-white text-opacity-80;
}

.swiper-button {
  @apply w-[1.719rem] h-[1.719rem] relative transition-opacity;

  &:before {
    content: '';
    @apply rounded-full border-2 border-beige transition absolute left-0 top-0 w-full h-full transform;
  }

  &:hover {
    &:before {
      @apply border-cherry scale-125;
    }
  }

  svg {
    @apply absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2;
  }

  &.swiper-prev {
    svg {
      @apply translate-x-[-30%];
    }
  }

  &.swiper-next {
    svg {
      @apply translate-x-[-70%];
    }
  }

  &.swiper-button-disabled {
    @apply opacity-40 pointer-events-none;
  }
}

.underline-anim {
  background-image: linear-gradient(90deg, currentColor 0%, currentColor 100%);
  background-size: 0% 2px;
  background-position: right bottom;
  background-repeat: no-repeat;
  transition: background-size 300ms ease;

  &:hover {
    background-position: left bottom;
    background-size: 100% 2px;
  }
}

::-webkit-scrollbar {
  @apply w-1 bg-dark-grey;
}
 
::-webkit-scrollbar-track {
  
}
 
::-webkit-scrollbar-thumb {
  @apply bg-beige rounded-full;
}

.br-fix {
  br {
    @media (max-width: 639px) {
      content:' ';
    }

    &:after {
      @media (max-width: 639px) {
        content:' ';
      }
    }
  }
}

.elastic-scroll {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

#canvas, #wipe {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
}

#wipe {
  @apply pointer-events-none z-[99999];
}

select:focus {
  @apply outline-none;
}

#content-wrapper {
  @apply overflow-hidden relative;
}

.floater-image {
  @apply transition-opacity duration-300;
}

.appear-mask {
  @apply overflow-hidden;
  clip-path: inset(100% 0% 0% 0%);

  &>* {
    @apply sm:transform sm:scale-[1.15];
  }

  &.is-visible {
    clip-path: inset(0% 0% 0% 0%);
  }
}