.website-form {
  .form-field {
    &.text-field {
      @apply pt-4 relative;

      input {
        @apply bg-transparent pt-6 pb-4 input-text w-full text-white border-b border-warm-grey border-opacity-30 transition;

        &:focus {
          @apply outline-none;
        }

        &:hover ~ label, &:focus ~ label {
          @apply translate-y-[-150%] scale-[0.8];
        }
      }

      label {
        @apply absolute left-0 top-1/2 transform -translate-y-1/2 description-text text-beige transition origin-top-left;
      }

      &:after {
        content: '';
        @apply absolute right-0 bottom-0 w-0 h-[1px] bg-beige;
        transition: width 300ms ease;
      }

      &:hover {
        &:after {
          @apply left-0 right-auto w-full;
        }
      }

      &.focus {
        label {
          @apply translate-y-[-150%] scale-[0.8];
        }
      }

      &.error {
        label {
          @apply text-cherry;
        }

        input {
          @apply border-cherry;
        }
      }
    }

    &.checkbox-wrapper {
      label {
        @apply check-label-text whitespace-nowrap flex items-center cursor-pointer relative text-beige transition;

        span {
          @apply flex-1 block;
        }
        &:before {
          content: '';
          @apply min-w-[1.313rem] w-[1.313rem] h-[1.313rem] mr-[0.625rem] border border-beige border-opacity-25 block rounded-md transition;
        }
        .checkmark {
          @apply transition opacity-0 absolute left-[0.14rem] bottom-[0.35rem];
        }
      }

      input {
        @apply hidden;

        &:checked ~ label {
          &:before {
            @apply bg-cherry border-cherry border-opacity-100;
          }

          .checkmark {
            @apply opacity-100;
          }
        }
      }

      &:hover {
        label {
          @apply text-white;

          &:before {
            @apply bg-cherry border-cherry border-opacity-100;
          }
        }
      }

      &.error {
        label {
          @apply text-cherry;

          &:before {
            @apply border-cherry;
          }
        }
      }
    }
  }
}