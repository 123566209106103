.pagination {
  grid-template-columns: auto auto auto;
  .prev-button, .next-button {
    @apply row-start-2;
  }

  .numbers {
    @apply row-start-1 col-span-2;
  }
  @screen sm {
    .prev-button, .next-button, .numbers {
      @apply row-start-1;
    }
  }

  .prev-button {
    &:hover {
      .arrow {
        animation: inArrow2 300ms 1 normal forwards;
      }
    }

    .arrow {
      @apply absolute right-auto left-0 rotate-180;
      animation: outArrow2 200ms 1 normal forwards;
    }
  }
}

@keyframes inArrow2 {
  0% {
    left: 0;
    transform: translate(0%, 0%) rotate(180deg);
  }
  50% {
    left: 0;
    transform: translate(-100%, 0%) rotate(180deg);
  }
  50.01% {
    left: 100%;
    transform: translate(0%, 0%) rotate(180deg);
  }
  100% {
    left: 100%;
    transform: translate(-100%, 0%) rotate(180deg);
  }
}

@keyframes outArrow2 {
  0% {
    @apply left-full -translate-x-full;
  }
  100% {
    @apply left-0 translate-x-0;
  }
}