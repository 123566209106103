.dropdown {
  .current-item {
    &.open {
      svg {
        @apply rotate-180;
      }
    }
  }

  .dropdown-item {
    @apply relative;
    transition: padding-left 300ms ease;

    &:before {
      content: '';
      @apply absolute right-0 top-0 w-0 h-full bg-cherry z-[-1];
      transition: width 300ms ease;
    }

    &:hover, &.selected {
      @apply pl-4;
      &:before {
        @apply w-full left-0;
      }
    }
  }
}

.dropdown-select {
  option {
    @apply bg-dark-grey;
  }

  option:checked, option:focus, option:hover, option:active  {
    @apply bg-cherry;
  }
}