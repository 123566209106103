.text-format {
  
  h2 {
    @apply block-title-text my-6 md:my-10 last:mb-0 first:mt-0 text-beige;

    @screen sm {
      font-size: 2.25rem;
      font-size: clamp(2.25rem,2.3958vw,2.875rem);
    }
  }

  h3 {
    @apply tertary-title-text my-8 last:mb-0 first:mt-0 text-beige;
  }

  h4 {
    @apply quaternary-title-text my-6 last:mb-0 first:mt-0 text-beige;
  }

  p {
    @apply mb-6;

    strong {
      @apply bold-description-text;
    }
  }

  &>*:last-child {
    @apply mb-0;
  }

  .two-cols {
    column-count: 2;
    column-gap: 5.25rem;
  }

  a:not(.button):not(.simple-button) {
    @apply text-cherry;
    background-image: linear-gradient(90deg, currentColor 0%, currentColor 100%);
    background-size: 0% 2px;
    background-position: right bottom;
    background-repeat: no-repeat;
    transition: background-size 300ms ease;

    &:hover {
      background-position: left bottom;
      background-size: 100% 2px;
    }
  }

  ul, ol {
    margin: 1em 1.5em;
    @apply leading-normal my-[1em] mx-[1.5em];
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }
}