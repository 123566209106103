.hero-banner {
  .media-wrapper {
    @apply left-12 lg:left-[200px] 2xl:left-[256px];

    @media (min-width: 1920px) {
      left: calc(50vw - 674px);
    }
  }

  &.center-layout {
    .media-wrapper {
      @apply left-0;
    }
  }
}
