@layer components {
  .primary-title-text {
    @apply font-soho font-extrabold text-[2rem] sm:text-[3rem];
    line-height: 1.08;

    @screen sm {
      font-size: clamp(3rem,4.1667vw,5rem);
    }
  }
  .secondary-title-text {
    @apply font-soho font-extrabold text-[3.125rem];
    line-height: 1.16;
  }
  .block-title-text {
    @apply font-soho font-extrabold text-2xl sm:text-4xl;
    line-height: 1.13;

    @screen sm {
      font-size: 2.25rem;
      font-size: clamp(2.25rem,2.3958vw,2.875rem);
    }
  }
  .big-menu-text {
    @apply font-soho font-medium text-[2rem] lg:text-5xl leading-normal;
    @screen lg {
      font-size: clamp(2rem,2.5vw,3rem);
    }
  }
  .tertary-title-text {
    @apply font-soho font-extrabold text-3xl sm:text-[2rem] leading-normal;
  }
  .quaternary-title-text {
    @apply font-soho font-extrabold text-[1.375rem];
    line-height: 1.36;
  }
  .input-text {
    @apply font-soho font-normal text-[1.375rem];
    line-height: 1.14;
    letter-spacing: 2.2px;
  }
  .quote-text {
    @apply font-soho font-bold text-base sm:text-xl leading-normal;
    letter-spacing: 1px;
  }
  .bold-description-text {
    @apply font-soho font-normal leading-normal text-lg xl:text-xl;
    letter-spacing: 1px;
  }
  .author-text {
    @apply font-soho font-medium text-lg;
    line-height: 1.67;
    letter-spacing: 1.8px;
  }
  .bigger-desc-text {
    @apply font-soho text-lg font-normal;
    line-height: 1.39;
    letter-spacing: 1.8px;
  }
  .button-text {
    @apply font-soho text-sm sm:text-[0.8125rem] xl:text-base font-extrabold leading-normal;
  }
  .menu-nav-text {
    @apply font-soho text-base font-medium leading-normal;
    letter-spacing: 3.2px;
  }
  .bigger-bold-body-text {
    @apply font-soho text-[0.9375rem] font-medium;
    line-height: 1.67;
    letter-spacing: 0.38px;
  }
  .description-text {
    @apply font-soho text-[0.9375rem] font-normal;
    line-height: 1.67;
    letter-spacing: 1.5px;
  }
  .bigger-body-text {
    @apply font-soho text-[0.9375rem] font-normal;
    line-height: 1.67;
    letter-spacing: 0.38px;
  }
  .simple-text {
    @apply font-soho text-[0.938rem] font-normal leading-loose;
    letter-spacing: 0.75px;
  }
  .filter-text {
    @apply font-soho text-sm font-extrabold leading-normal;
  }
  .author-desc-text {
    @apply font-soho text-sm font-medium;
    line-height: 2.14;
    letter-spacing: 1.4px;
  }
  .body-text {
    @apply font-soho text-sm font-normal;
    line-height: 1.79;
    letter-spacing: 0.7px;
  }
  .note-text {
    @apply font-soho text-[0.8125rem] font-bold leading-normal italic;
    letter-spacing: 1.3px;
  }
  .smaller-body-text {
    @apply font-soho text-[0.8125rem] font-medium;
    line-height: 1.92;
    letter-spacing: 0.33px;
  }
  .small-menu-text {
    @apply font-soho text-[0.8125rem] font-medium leading-normal;
    letter-spacing: 1.3px;
  }
  .check-label-text {
    @apply font-soho text-[0.8125rem] font-normal;
    line-height: 1.92;
  }
  .number-text {
    @apply font-soho text-xs font-bold leading-normal;
  }
  .active-label-text {
    @apply font-soho text-xs font-normal;
    line-height: 2.08;
    letter-spacing: 1.2px;
  }
  .small-body-text {
    @apply font-soho text-[0.6875rem] font-medium leading-normal;
    letter-spacing: 1.1px;
  }
  .caption-text {
    @apply font-soho text-[0.625rem] font-normal;
    line-height: 2.5;
    letter-spacing: 1px;
  }
}