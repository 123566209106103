.navigation, .footer {
  #menu-button {
    @apply transform transition relative bg-cherry;

    .button-bg {
      @apply fill-cherry absolute left-0 top-0 overflow-visible;
    }
    .menu-svg {
      @apply overflow-visible relative z-10;

      .line {
        @apply transform transition-all duration-150; 
      }

      .chevron {
        @apply transition-opacity opacity-0;
      }
    }

    &:hover {
      .line-2 {
        @apply lg:-rotate-45 lg:origin-top-left lg:translate-y-[55%] lg:translate-x-[10%] lg:scale-x-75;
      }
      .line-3 {
        @apply lg:rotate-45 lg:origin-top-left lg:translate-y-[-30%] lg:translate-x-[55%] lg:scale-x-75;
      }
    }

    &.open {
      .line-1 {
        @apply rotate-45 origin-center;
      }

      .line-2 {
        @apply -rotate-45 origin-top-left translate-y-[55%] translate-x-[-35%] scale-x-75;
      }
      .line-3 {
        @apply rotate-[135deg] origin-top-left translate-y-[-10%] translate-x-[94%] scale-x-75;
      }
    }

    &.locations-open {
      .line {
        @apply opacity-0;
      }

      .chevron {
        @apply opacity-100;
      }
    }

    &.up {
      @apply -rotate-90;
      .line-2 {
        @apply -rotate-45 origin-top-left translate-y-[55%] translate-x-[10%] scale-x-75;
      }
      .line-3 {
        @apply rotate-45 origin-top-left translate-y-[-30%] translate-x-[55%] scale-x-75;
      }
    }
  }

  .main-menu-item {
    &:after {
      content: '';
      @apply absolute right-0 top-1/2 w-0 h-[1px] bg-white bg-opacity-20 transform mt-2 pointer-events-none hidden md:block;
      transition: width 300ms ease;
    }

    .outlink {
      @apply w-8 ml-4 transform transition duration-300;
    }

    &:hover, &.active {
      &:after {
        @apply w-full left-0;
      }

      .link-text {
        @apply text-cherry md:translate-x-4;
      }

      .outlink {
        @apply md:translate-x-4;
      }
    }
  }

  &>.inner {
    transition: padding-top 300ms ease, background-color 300ms ease, transform 300ms ease;
  }
  .star-stroke {
    @apply opacity-0 md:opacity-100;
  }
  .active {
    .star-stroke {
      @apply opacity-100;
      stroke-dashoffset: initial!important;
      stroke-dasharray: initial!important;
    }
  }

  .location-wrapper {
    .location-menu-item {
      @apply pl-0;
      transition: padding-left 0.3s ease;

      &:after {
        content: '';
        @apply absolute -left-4 md:-left-6 -right-4 md:-right-6 -top-2 md:-top-3 -bottom-2 md:-bottom-3 border border-beige border-opacity-30 rounded-3xl opacity-0 pointer-events-none;
      }

      svg {
        @apply transition duration-300 opacity-0 pointer-events-none;
      }

      &:hover, &.active {
        @apply pl-10;

        &:after {
          @apply opacity-100;
        }

        svg {
          @apply delay-100 opacity-100;
        }
      }
    }
  }

  .star-anim {
    .inner-wrapper {
      span {
        will-change: transform;
        display: block;
        transition: transform 1s cubic-bezier(.19,1,.22,1);
        transform-origin: left center;

        &:after {
          content: attr(data-text);
          will-change: transform;
          display: block;
          position: absolute;
          top: 160%;
          transform-origin: left center;
          color: #fff;
          transform: skewY(30deg);
          transition: transform 1s cubic-bezier(.19,1,.22,1),
        }
      }
      
    }

    &:hover {
      .inner-wrapper {
        span {
          transform: translateY(-160%) skewY(7deg);

          &:after {
            transform: skewY(-7deg);
          }
        }
      }
    }
  }

  .secondary-menu {
    @apply xl:ml-20;
    @media (min-width: 1570px){
      margin-left: 8rem;
    }
  }
}