.cta-block {
  .inner {
    @apply bg-center bg-no-repeat bg-cover;

    @screen lg {
      background-image: url('../../../assets/bg_texture_wood_heim.jpg');
    }
  }
}

.sound-toggle {
  .sound-icon {
    .sound-wave {
      @apply transition-opacity;
    }
  }

  &.unmuted {
    .sound-icon {
      .sound-wave {
        @apply opacity-0;
      }
    }
  }
}