#consentOverlay {
	transition: opacity .3s;
  
	&.active {
		opacity: 1;
	}
	
  #consentBasicContainer {
		transition: opacity .3s;
		pointer-events: none;
		opacity: 0;

		&.active {
			opacity: 1;
			pointer-events: all;
		}
	}
	
  #consentAdvancedContainer {
		transition: opacity .3s;
		pointer-events: none;
		opacity: 0;

		&.active {
			opacity: 1;
			pointer-events: all;
		}

		input[type="checkbox"] {
			@apply w-0 h-0 absolute;
		}
		input[type="checkbox"]:checked + label {
			svg {
				path[fill="#18191a"]{
					@apply opacity-100;
				}
			}
		}

		input[type="checkbox"]:checked:disabled + label {
			svg {
				@apply opacity-40;
			}
		}

		label {
			@apply flex items-center cursor-pointer w-full justify-between;

			svg {
				path[fill="#18191a"]{
					@apply opacity-0 transition-opacity;
				}
			}
		}
	}
}