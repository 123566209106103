.entry-locations, .entry-galleries {
  
}

.location-contacts {
  @apply bg-center bg-repeat-y bg-cover;

  @screen lg {
    background-image: url('../../assets/bg_texture_wood_heim.jpg');
  }
}