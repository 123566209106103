.entry-menu {
  .food-category {
    background-image: linear-gradient(90deg, theme('colors.cherry') 0%, theme('colors.cherry') 100%);

    &.active {
      @apply text-white;

      background-size: 100% 2px;
    }
  }

  .pin-spacer {
    @apply z-10;
  }

  .food-categories-select {
    option {
      @apply bg-dark-grey;
    }

    option:checked, option:focus, option:hover, option:active  {
      @apply bg-cherry;
    }
  }

  .filters {
    @apply transition duration-300;

    transition-property: top;
  }
}