.button {
  @apply flex items-center relative;

  .text {
    @apply filter-text text-white block px-4 sm:px-5 py-3 sm:py-4 rounded-full whitespace-nowrap flex-1 relative z-10 bg-cherry transform transition-transform duration-300;
  }
  .circle, .circle2 {
    @apply flex items-center justify-center relative rounded-full w-[2.817rem] sm:w-[3.25rem] h-[2.817rem] sm:h-[3.25rem] z-10 bg-cherry transform origin-right transition-transform duration-300;
  }
  .circle2 {
    @apply absolute left-0 top-0 scale-0 origin-left;
  }
  .arrow {
    @apply fill-dark-grey w-[26px] relative;
  }

  .button-bg {
    @apply absolute left-0 top-0 w-full h-full fill-cherry overflow-visible;
    
    .rect, .circ, .circ2 {
      @apply transform transition-transform duration-300;
    }
    .circ {
      @apply origin-right;
    }
    .circ2 {
      @apply origin-left scale-0;
    }
  }

  &:hover:not(.sound-toggle) {
    .button-bg {
      .circ {
        @apply scale-0;
      }
      .circ2 {
        @apply scale-100;
      }
      .rect {
        @apply translate-x-[2.817rem] sm:translate-x-[3.25rem];
      }
    }

    .text {
      @apply translate-x-[2.817rem] sm:translate-x-[3.25rem];
    }

    .circle {
      @apply scale-0;
    }

    .circle2 {
      @apply scale-100;
    }
  }
}

.simple-button {
  @apply inline-flex filter-text items-center transition hover:text-cherry overflow-hidden relative px-10 -ml-10;

  .arrow {
    @apply fill-cherry transform absolute right-0;
    animation: outArrow 200ms 1 normal forwards;
  }

  &:hover {
    .arrow {
      animation: inArrow 300ms 1 normal forwards;
    }
  }
}

.group {
  &:hover {
    .simple-button {
      .arrow {
        animation: inArrow 300ms 1 normal forwards;
      }
    }
  }
}

@keyframes inArrow {
  0% {
    right: 0;
    transform: translate(0%, 0%);
  }
  50% {
    right: 0;
    transform: translate(100%, 0%);
  }
  50.01% {
    right: 100%;
    transform: translate(0%, 0%);
  }
  100% {
    right: 100%;
    transform: translate(100%, 0%);
  }
}

@keyframes outArrow {
  0% {
    @apply right-full translate-x-full;
  }
  100% {
    @apply right-0 translate-x-0;
  }
}