@font-face {
    font-family: 'SohoPro';
    src: url('../../assets/fonts/SohoPro-Regular.eot');
    src: local('Soho Pro Regular'), local('SohoPro-Regular'),
        url('../../assets/fonts/SohoPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/SohoPro-Regular.woff2') format('woff2'),
        url('../../assets/fonts/SohoPro-Regular.woff') format('woff'),
        url('../../assets/fonts/SohoPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SohoPro';
    src: url('../../assets/fonts/SohoPro-Medium.eot');
    src: local('Soho Pro Medium'), local('SohoPro-Medium'),
        url('../../assets/fonts/SohoPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/SohoPro-Medium.woff2') format('woff2'),
        url('../../assets/fonts/SohoPro-Medium.woff') format('woff'),
        url('../../assets/fonts/SohoPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'SohoPro';
    src: url('../../assets/fonts/SohoPro-Bold.eot');
    src: local('Soho Pro Bold'), local('SohoPro-Bold'),
        url('../../assets/fonts/SohoPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/SohoPro-Bold.woff2') format('woff2'),
        url('../../assets/fonts/SohoPro-Bold.woff') format('woff'),
        url('../../assets/fonts/SohoPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'SohoPro';
    src: url('../../assets/fonts/SohoPro-ExtraBold.eot');
    src: local('Soho Pro ExtraBold'), local('SohoPro-ExtraBold'),
        url('../../assets/fonts/SohoPro-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/SohoPro-ExtraBold.woff2') format('woff2'),
        url('../../assets/fonts/SohoPro-ExtraBold.woff') format('woff'),
        url('../../assets/fonts/SohoPro-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'SohoPro';
    src: url('../../assets/fonts/SohoPro-Heavy.eot');
    src: local('Soho Pro Heavy'), local('SohoPro-Heavy'),
        url('../../assets/fonts/SohoPro-Heavy.eot?#iefix') format('embedded-opentype'),
        url('../../assets/fonts/SohoPro-Heavy.woff2') format('woff2'),
        url('../../assets/fonts/SohoPro-Heavy.woff') format('woff'),
        url('../../assets/fonts/SohoPro-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}